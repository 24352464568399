//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import restBill from "./../../table/restBill";
import changBill from "./../../table/changBill";
import drawBill from "./../../table/drawBill";
import refundBill from "./../../table/refundBill";
import invalidatedBill from "./../../table/invalidatedBill";
import detailList from "@/lib/data-service/flight/flight_order_report_list";
import exportExcel from "@/lib/data-service/flight/exportExcel";

export default {
  data() {
    return {
      searchForm: {
        currentPage: 1,
        pageSize: 10,
        flightType: 1, // 1 国内 2 国际
        ticketDate: [],
        applyDate: [],
        OrdersDate: [],
        serviceStatus: ""
      },
      pageData: [],
      NavItemActive: 0,
      regionalTypeList: [],
      loading: false,
      totalCount: 0,
      navType: 1, // 1 出票 3 退票 2 改签 4 废票 5 其他
      selectList: [],
      excelForm: {}
    };
  },
  components: {
    invalidatedBill,
    refundBill,
    drawBill,
    changBill,
    restBill
  },
  props: {
    changeRecordList: {
      type: Array,
      default: []
    },
    flightType: Number
  },
  methods: {
    search() {
      let data = this.searchForm;
      data.serviceType = this.navType;
      console.log(data)
      let timeForm = this.navType === 1 ? {
        ticketDate: ["ticketStartDate", "ticketEndDate"],
        OrdersDate: ["applyStartDate", "applyEndDate"]
      } : {
        applyDate: ["applyStartDate", "applyEndDate"],
      };
      Object.keys(timeForm).map(key => {
        getTime(timeForm[key], data[key]);
      });
      function getTime(timeList, dateList) {
        if (!dateList || dateList.length == 0) {
          timeList.map((name, index) => {
            data[name] = "";
          });
          return;
        }
        timeList.map((name, index) => {
          console.log(name, index)
          data[name] = dateList[index];
        });
      }
      if (this.navType === 1) {

      } else {

      }

      detailList(data).then(res => {
        console.log(res);
        this.pageData = res.pageResult.pageData;
        this.totalCount = res.pageResult.totalCount;
      });
    },
    getItem(val) {
      // this.selectList = val;
      this.selectList = val.map(item => {
        return item.id;
      });
    },
    reset() {
      this.searchForm = {
        currentPage: 1,
        pageSize: 10,
        flightType: this.flightType,
        serviceStatus: ""
      };
      this.search();
    },
    _exportExcel() {
      // debugger
      if (this.pageData.length === 0)
        return this.$message({ type: "warning", message: "空数据无法导出！" });
      let data = this.excelForm,
        selectList = this.selectList;
      // data.serviceType === 1
      //   ? (data.ticketReportExportList = selectList)
      //   : data.serviceType === 2
      //     ? (data.changeReportExportList = selectList)
      //     : data.serviceType === 3
      //       ? (data.refundReportExportList = selectList)
      //       : data.serviceType === 4
      //         ? (data.invalidateReportExportList = selectList)
      //         : (data.otherReportExportList = selectList);
      selectList.length > 0 ? (data.ids = selectList.join(",")) : delete data.ids;
      if (this.navType === 1 && this.searchForm.OrdersDate && this.searchForm.OrdersDate.length) {
        data.applyStartDate = this.searchForm.OrdersDate[0];
        data.applyEndDate = this.searchForm.OrdersDate[1];
      }
      if (this.navType !== 1 && this.searchForm.applyDate && this.searchForm.applyDate.length) {
        data.applyStartDate = this.searchForm.applyDate[0];
        data.applyEndDate = this.searchForm.applyDate[1];
      }
      if (this.searchForm.ticketDate && this.searchForm.ticketDate.length) {
        data.ticketStartDate = this.searchForm.ticketDate[0];
        data.ticketEndDate = this.searchForm.ticketDate[1];
      }
      exportExcel(data).then(res => {

        try {
          let uInt8Array = new Uint8Array(res);
          let blob = new Blob([uInt8Array], { type: "application/vnd.ms-excel" });
          let blobURL = window.URL.createObjectURL(blob);
          let tempLink = document.createElement("a");
          tempLink.style.display = "none";
          tempLink.href = blobURL;
          tempLink.setAttribute("download", data.sheetName+ '.xlsx');
          document.body.appendChild(tempLink);
          tempLink.click();
          document.body.removeChild(tempLink);
          window.URL.revokeObjectURL(blobURL);
        } catch (e) {
          console.log(e);
        }


      });
    },
    statuSearch(item, index) {
      this.NavItemActive = index;
      this.navType = item.value;
      this.searchForm.currentPage = 1;
      this.selectList.length = 0;
      this.searchForm.serviceStatus = "";
      item.value === 1
        ? (this.regionalTypeList = [
            { value: "", label: "全部" },
            { value: 21, label: "待出票" },
            { value: 22, label: "出票成功" },
            { value: 23, label: "出票失败" }
          ])
        : item.value === 2
          ? (this.regionalTypeList = [
              { value: "", label: "全部" },
              { value: 31, label: "处理中" },
              { value: 35, label: "改签成功" },
              { value: 36, label: "改签失败" },
              { value: 34, label: "已补款" },
              { value: 37, label: "已取消" }
            ])
          : item.value === 3
            ? (this.regionalTypeList = [
                { value: "", label: "全部" },
                //{ value: 41, label: "申请中" },
                //{ value: 44, label: "处理中" },
                { value: 45, label: "退票成功" },
                //{ value: 46, label: "退票失败" },
                { value: 52, label: "退款成功" },
                //{ value: 47, label: "已取消" }
              ])
            : item.value === 4
              ? (this.regionalTypeList = [
                  { value: "", label: "全部" },
                  { value: 1, label: "处理中" },
                  { value: 2, label: "废票成功" },
                  { value: 3, label: "废票失败" }
                ])
              : (this.regionalTypeList = [
                  { value: "", label: "全部" },
                  { value: 0, label: "待处理" },
                  { value: 1, label: "已处理" }
                ]);
      let data = {
        title: item.text,
        serviceType: item.value,
        fileName: this.flightType === 1 ? "国内对账" : "国际对账",
        sheetName: this.flightType === 1 ? "国内对账" : "国际对账",
        flightType: this.flightType
      };
      this.excelForm = data;
      this.search();
    },
    handlerSize(val) {
      this.searchForm.pageSize = val;
      this.search();
    },
    handlerPage(val) {
      this.searchForm.currentPage = val;
      this.search();
    }
  },
  activated() {
    this.searchForm.flightType = this.flightType;
    this.searchForm.OrdersDate = [
      this.$moment()
        .subtract(3, "months")
        .format("YYYY-MM-DD"),
      this.$moment().format("YYYY-MM-DD")
    ];

    this.searchForm.applyDate = [
      this.$moment()
              .subtract(3, "months")
              .format("YYYY-MM-DD"),
      this.$moment().format("YYYY-MM-DD")
    ];
    this.statuSearch(this.changeRecordList[0], 0);
  }
};
