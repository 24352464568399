//
//
//
//
//

import ListPage from "@/page/distributor/accountsChecking/component/listPage/1.0.0/listPage.vue";
export default {
  data() {
    return {
      changeRecordList: [
        { text: "出票订单", value: 1 },
        { text: "退票订单", value: 3 },
        { text: "改签订单", value: 2 },
        { text: "废票订单", value: 4 },
        { text: "其他", value: 5 }
      ]
    };
  },
  components: { ListPage },
  methods: {}
};
